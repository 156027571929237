.date-picker {
  text-align: center;
  padding: 10px;
  user-select: none;
}

.date-picker .confirmation {
  text-align: right;
}

.date-picker .confirmation span {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
  color: var(--light-red);
}

.date-picker .confirm {
  width: fit-content;
  margin: 0px;
  font-size: 13px;
  background-color: transparent;
  outline: none;
  border: none;
}

.confirm-button {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}