.root {
  min-height: 100vh;
  width: 100%;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
