.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.info-block {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 50%;

  div {
    padding-right: 1rem;
  }
}

.info-line {
  display: flex;
}

.value {
  color: var(--grey);
}

.label {
  text-wrap: nowrap;
}

.charge {
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  color: var(--grey);
}

.charge-amounts {
  display: flex;

  div {
    padding-left: 3rem;
  }
}

.charge-total {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;

  div {
    margin-left: 1rem;
  }

  .value {
    border-bottom: solid 2px black;
    border-top: solid 1px black;
  }
}