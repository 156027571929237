body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--off-white) !important;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
  color: var(--off-black);
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.hidden-fade {
  animation-name: fade-out;
  animation-timing-function: ease;
  animation-duration: 0.7s;
  opacity: 0;
}

.show-fade {
  animation-name: fade-in;
  animation-timing-function: ease;
  animation-duration: 0.7s;
}

.title {
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 1px solid var(--theme-green);
  margin-bottom: 1rem;
  font-weight: 800;
}

.title span {
  color: var(--theme-blue);
}

.cap {
  text-transform: capitalize;
}

.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.fullscreen-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relative {
  position: relative;
}

.subtle-heading {
  font-weight: 500;
  color: var(--grey);
  letter-spacing: 0.5px;
}

.grey-underline {
  border-bottom: 1.5px solid var(--light-grey);
  padding-bottom: 0.5rem;
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
}

.bold {
  font-weight: bold;
}

.no-select {
  user-select: none;
}

:root {
  --theme-green: rgb(168, 207, 69);
  --theme-green-faded: rgb(168, 207, 69, 0.5);
  --theme-dark-green: rgb(86, 120, 42);
  --theme-grey: rgb(123, 123, 125);
  --theme-blue: rgb(41, 73, 94);
  --theme-blue-light: rgb(158, 216, 219);
  --theme-red: rgb(255, 41, 41);
  --grey: rgb(101, 116, 130);
  --light-grey: rgb(215, 215, 215);
  --light-red: rgb(255, 41, 41);
  --off-white: rgb(250, 250, 250);
  --off-black: rgb(60, 59, 55);
}

@keyframes fade-in {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
