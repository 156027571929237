.headers, .row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;

  div {
    width: 100px;
  }
}

.headers {
  border-bottom: 1px solid var(--light-grey);
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.container {
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
