.entity-box {
  margin: 1rem;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  height: 375px;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: var(--theme-blue);
  color: white;
  padding: 0.5rem
}

.loader-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.entity {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.entity-container {
  overflow-y: auto;
}

.submit-edit {
  display: flex;
  font-size: 0.8rem;
  align-items: center;
}

.cancel {
  padding-right: 2rem;
  color: var(--theme-red);
}

.save {
  color: green
}

.create {
  display: flex;
  align-items: center;
  div {
    padding-left: 0.5rem;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.regionSelect {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--theme-border);
  background-color: white;
  margin-left: 10px;
  
  &:focus {
    outline: none;
    border-color: var(--theme-blue);
  }
}