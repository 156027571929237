.header {
  border-bottom: 1.5px solid rgb(198, 198, 198);
  background-color: white;
}

.logout {
  padding-top: 1.5rem;
  text-align: center;
  height: calc(100% - 70px);
  overflow-y: auto;
}

.logout a,
.logout span {
  display: block;
  font-weight: 500;
  border-radius: 50px;
  margin: 1.5rem;
}

.logout a {
  color: white;
}

.logout span {
  color: white;
  background-color: var(--theme-green);
  width: fit-content;
  padding: 5px 20px;
  margin: auto;
}

.logout a:active {
  color: var(--theme-darker);
  text-decoration: none;
}

.active {
  border-bottom: 2px var(--theme-green) solid;
  color: var(--theme-blue);
}

.link-box {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  a {
    color: var(--theme-blue);
  }
}