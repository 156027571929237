.pdf-container {
  width: 100%;
  height: calc(100vh - 120px);
}

.pdf {
  height: calc(100% - 40px);
}

.buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}

.download {
  cursor: pointer;
  a {
    color: white;
    background-color: var(--theme-green);
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 5px;
    user-select: none;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.cancel {
  user-select: none;
  cursor: pointer;
}