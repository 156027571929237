$column-width: 200px;

.equal-width-columns {
  width: $column-width;
}

.fit-content-column {
  width: auto !important;
  flex: 0 0 auto !important;
}

.filter-value {
  color: grey;
}

.checkboxes {
  display: flex;
  justify-content: space-around;
}

.filter-dropdown {
  div {
    --bs-dropdown-link-active-bg: var(--theme-green);
  }
}

.filter-dropdown-toggle {
  color: black !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;

  &:focus {
    box-shadow: none !important;
  }

  &:active {
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.loads-box,
.full-loads-box {
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  min-height: 375px;
  max-height: calc(100vh - 250px);  /* Adjust this value as needed */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.completed-loads-box {
  max-height: calc(100vh - 270px);
}

.loads-box {
  .columns {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .loads-content {
    overflow-y: auto;
    flex: 1;
  }
}

.full-loads-box {
  overflow-x: auto;

  .full-headers {
    display: flex;
    position: sticky;
    top: 0;
    background-color: white;  /* Ensure headers are visible */
    z-index: 1;  /* Ensure headers stay on top */
    width: fit-content;

    div {
      width: 200px;
    }
  }

  .full-loads {
    display: flex;
    flex-direction: column;
    width: fit-content;
    overflow-y: auto;
  }
}

.columns,
.full-headers {
  padding: 0.5rem;
  border-bottom: 1px solid var(--light-grey);
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.create-new-load {
  display: flex;
  justify-content: center;

  div {
    cursor: pointer;
    color: var(--theme-blue);
    padding-top: 1rem;
    -webkit-user-select: none;  /* Safari */
    -moz-user-select: none;     /* Firefox */
    -ms-user-select: none;      /* Internet Explorer/Edge */
    user-select: none;
  }

  div:active {
    transform: scale(0.98);
  }
}

.search {
  max-width: 300px;
}

.sort-header {
  cursor: pointer;
}

.toggle {
  align-items: flex-end;
  display: flex;
  height: 40px;
  align-self: center;
  justify-content: flex-end;

  div {
    display: flex;
    font-size: small;
    align-items: center;

    div {
      padding-right: 0.5rem;
    }
  }
}

@media (max-width: 1100px) {
  .hide-tablet {
    display: none;
  }
}

@media (max-width: 800px) {
  .hide-mobile {
    display: none;
  }
}
