.documents {

}

.download {
  color: black;
}

.download:active {
  color: white;
}

.doc {
  display: flex;
  align-items: center;
}

.dropdown {
  button {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
  }

  div {
    button {
      background-color: white;
    }

    --bs-dropdown-link-active-bg: var(--theme-green);
  }

  margin-left: auto;
  margin-right: 1rem;
}

.greyed-out {
  opacity: 0.5;
}

.upload {
  margin-left: auto;
  margin-right: 1.8rem;
  cursor: pointer;
}