.admin-menu {
  display: flex;
  justify-content: space-around;
}

.admin-menu {
  a {
    color: white;
    font-weight: 500;
  }
}

.active {
  background-color: white;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: var(--theme-blue) !important;
}
