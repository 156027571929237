.modal {
  width: fit-content;
  max-width: 500px;
  margin: auto;
}

.footer {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}