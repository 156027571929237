.inline {
  margin: 0px !important;
}

.dropdown {
  position: relative;
  margin: 4px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--theme-green);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
  cursor: pointer;
  width: calc(100% - 10px) !important;
  min-width: 100px;
  box-sizing: border-box;
  padding: 5px 10px;
}

.dropdownList {
  position: absolute;
  width: calc(100%);
  z-index: 10;
  background-color: white;
  border: 1px solid var(--theme-green);
  border-radius: 5px;
  list-style: none;
  padding: 0;
  margin: 0px -10px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.upward {
  bottom: 100% !important;
  top: auto;
}

.downward {
  top: 100%;
}

.dropdownItem {
  padding: 10px;
  border-bottom: 1px solid var(--light-grey);
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: var(--light-grey);
  }
}

.searchInput {
  width: calc(100% - 20px);
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
}

// Handle focus and invalid states
.dropdown:focus-within {
  border: 2.5px solid var(--theme-green);
  margin: 3px;
}

.dropdown.invalid {
  border: 1.5px solid var(--light-red);
}

.dropdown.invalid:focus-within {
  border: 2.5px solid var(--light-red);
}

.loader-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
}
