.header-right {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.options-toggle {
  display: flex;
  align-items: center;
  color: var(--off-black);
}

.options-toggle-outer {
  background-color: transparent !important;
  border: none !important;
  border-radius: 100px !important;
}

.options-toggle-outer:focus {
  box-shadow: none !important;
}

.active {
  background-color: var(--theme) !important;
}

.navigation {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

.navigation a {
  font-weight: 500;
  color: var(--theme);
}

.navigation a:hover {
  color: var(--theme);
  transform: scale(1.04);
  transition: 0.1s ease;
  text-decoration: none;
}

.dropdown-item {
  a {
    color: var(--off-black);
    &:hover {
      color: var(--off-black);
    }
  }
}

.dropdown-item:hover {
  background-color: var(--theme-faded) !important;
}

.dropdown-item:focus {
  outline: none !important;
  border: none !important;
  background-color: var(--theme) !important;
}

.active {
  border-bottom: 2px var(--theme-green) solid;
  color: var(--theme-blue);
}

.link-box {
  a {
    color: var(--theme-blue);
  }
  margin-right: 2rem;
  border-bottom: 2px white solid;
}