.new-charge {
  display: flex;
}

.quantity {
  width: 85px;
  position: relative;
  input {
    text-align: center;
  }
}

.currency {
  input {
    text-align: right;
  }
}

.x {
  padding-top: 10px
}

.charge {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.remove {
  text-align: center;
  cursor: pointer;
  color: var(--theme-red);
  display: flex;
  align-items: center;
}

.charges {
  padding-bottom: 1rem;
  padding-top: 1rem;
}