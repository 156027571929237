.outer {
  perspective: 100px;
  width: fit-content;
}

.main, .main-red {
  padding: 0.2rem 2rem !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 3.3px 10px !important;
  transition: all 0.25s ease-in-out;
}

.main {
  background-color: var(--theme-green) !important;
}

.main-red {
  background-color: var(--theme-red) !important;
}

.main:hover, .main-red:hover {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px !important;
  transition: all 0.15s ease-in-out;
  transform: scale(1.02);
  cursor: pointer;
}

.main:active, .main-red:active {
  transform: scale(0.98);
}

.radio {
  color: var(--theme-green) !important;
  background-color: unset !important;
  padding: 0.2rem 2rem !important;
  border: none !important;
}

.radio:hover {
  color: var(--theme-darker) !important;
  cursor: pointer;
}

.radio-active {
  background-color: var(--theme-green) !important;
  padding: 0.2rem 2rem !important;
  border: none !important;
  transition: all 0.25s ease-in-out;
  box-shadow: none !important;
  cursor: default !important;
}

.radio:active {
  box-shadow: 0px 0px 4px 0px var(--theme-green) !important;
}
