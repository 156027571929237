.widgetContainer {
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  align-items: center;
}

.widgetHeader {
  width: 100%;
  background: var(--theme-blue);
  padding: 10px 15px;
  color: white;

  h6 {
    margin: 0;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-style: italic;
  color: #666;
}

.widgetContent {
  width: 100%;
}