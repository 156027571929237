.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.grow-on-scroll {
  background-color: var(--theme-blue);
}