.equal-width-columns {
  flex: 1; // Makes columns flex items with equal width
}

.fit-content-column {
  width: auto !important;
  flex: 0 0 auto !important;
}

.load-item {
  padding: 0.5rem;
  font-size: small;
}

.selected {
  background-color: var(--theme-green-faded);
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding-left: 5px;
  padding-right: 5px;
}

.inline-input {
  input {
    margin: 0px !important;
  }
}

.dropdown {
  button {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    padding-top: 3px;
    padding-bottom: 3px;
  }

  div {
    button {
      background-color: white;
    }

    --bs-dropdown-link-active-bg: var(--theme-green);
  }

  margin-left: auto;
  margin-right: 1rem;
}
