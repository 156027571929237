.days {
  width: 245px;
  margin: auto;
}

.days .days-inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.days .weekdays span {
  display: inline-block;
  width: 35px;
}
