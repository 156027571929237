.button-box {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  align-items: center;
}

.button-box a {
  color: var(--theme-green) !important;
  font-weight: 500;
}

.forgot {
  font-size: 0.8rem;
  text-align: right;
}

.forgot span {
  cursor: pointer;
}

.input-box {
  margin-bottom: 1.5rem;
}

.window {
  width: 350px;
}

@media screen and (max-width: 700px) {
  .window {
    width: 300px;
  }
}

@media screen and (max-width: 350px) {
  .window {
    width: 85vw;
  }
}
