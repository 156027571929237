.bottom-up-menu {
  position: fixed;
  z-index: 200;
  width: 100vw;
  left: 0px;
  height: 100vh;
  border-top: 3px solid var(--theme-green);
  background-color: white;

  a, span {
    font-size: 1.5rem;
  }
}

.open {
  top: 50px;
  transition: all 0.4s ease-in-out;
}

.closed {
  top: 100%;
  transition: all 0.4s ease-in-out;
}

.menu-icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 15px;
}