.backdrop {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.stacked-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.parent {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  align-items: center;
  animation-name: fade;
  animation-duration: 0.5s;
}

.modal {
  position: relative;
  z-index: 200;
  background-color: white;
  padding: 2rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: fit-content;
  overflow-y: auto;
  max-height: 95vh;
  margin: auto;
  width: fit-content;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
