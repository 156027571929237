.day {
  width: 35px;
  height: 35px;
  border-radius: 3px;
}

.day.real-day {
  cursor: pointer;
}

.day.selected {
  background-color: var(--theme-green);
  color: white;
}

.day.invalid {
  background-color: var(--light-red);
  color: white;
}
