.window {
  z-index: 1;
  position: relative;
  width: fit-content;
  background-color: white;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: rgb(0, 0, 0) 0px 5px 15px !important;
}

.window-hidden {
  z-index: 1;
  position: relative;
  width: fit-content;
  padding: 1.5rem;
}

.window-outer {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  background-image: url('../../assets/images/background.jpg');
  background-position: center;
  background-size: cover;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.logo img {
  width: 250px;
}

@media screen and (max-width: 600px) {
  .logo {
    text-align: center;
    width: 100%;
    left: 0px;
    top: 1rem;
  }
}
