.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tri-input {
  div {
    width: 100%;
  }
}

.single-input {
  display: flex;
  justify-content: space-around;
}