.prices-container {
  padding: 1rem;
}

.prices-content {
  height: 100%;
}

.client-list-body {
  padding: 0;
}

.client-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.client-item {
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 0.25rem 1.25rem;
  border-bottom: 1px solid rgba(0,0,0,.125);

  &:last-child {
    border-bottom: none;
  }

  &.active {
    background-color: var(--theme-green);
    border-color: var(--theme-green);
  }
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.card {
  border: none;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.card-header {
  border-bottom: 1px solid var(--light-grey);
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  background-color: white;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}