@import '../../../assets/scss/animations/heartBeat';

.header-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .header-left:hover {
//   animation: heartbeat 1.5s both infinite;
// }

.header-left img {
  height: 50px;
  margin: 10px;
}

@media only screen and (max-width: 575px) {
  .header-left span {
    font-size: 20px;
  }

  .header-left img {
    height: 40px;
    margin: 5px;
  }
}
