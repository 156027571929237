.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid var(--theme-green);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  &.tiny {
    width: 16px;
    height: 16px;
}

  &.small {
      width: 25px;
      height: 25px;
  }

  &.medium {
      width: 50px;
      height: 50px;
  }

  &.large {
      width: 100px;
      height: 100px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
