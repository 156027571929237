.main.invalid,
.main-select.invalid {
  border: 1.5px solid var(--light-red);
}

.main {
  margin: 4px;
  box-sizing: border-box;
  width: calc(100% - 8px);
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--theme-green);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
  input {
    border: none;
    outline: none;
  }
}

.main:focus,
.main-select:focus {
  border: 2.5px solid var(--theme-green);
  margin: 3px;
}

.main.main.invalid:focus {
  border: 2.5px solid var(--light-red);
  margin: 3px;
}

.main-select {
  margin: 4px;
  border: none;
  padding: 3px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--theme-green);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
}

.label {
  margin-right: 8px;
}

.edit.invalid,
.edit-select.invalid {
  border: 1.5px solid var(--light-red);
}

.edit {
  box-sizing: border-box;
  width: calc(100% - 8px);
  padding: 2px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--light-grey);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
}

.edit:focus,
.edit-select:focus {
  border: 1.5px solid var(--light-grey);
}

.edit.edit.invalid:focus {
  border: 2.5px solid var(--light-red);
}

.time {
  margin: 4px;
  box-sizing: border-box;
  width: calc(100% - 8px);
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid var(--theme-green);
  border-radius: 5px;
  outline: none;
  color: var(--off-black);
}

.time:focus {
  border: 2.5px solid var(--theme-green);
  margin: 3px;
}

.time.invalid {
  border: 1.5px solid var(--light-red);
}

.time.invalid:focus {
  border: 2.5px solid var(--light-red);
  margin: 3px;
}

.checkbox {
  transform: scale(1.5);
}

.halfTicked {
  accent-color: var(--theme-red);
}