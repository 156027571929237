.months {
  font-size: 15px;
  user-select: none;
}

.months .arrow {
  cursor: pointer;
  width: 50px;
}

.months span {
  display: inline-block;
  padding: 5px 0px;
}

.display-value {
  width: 120px;
}