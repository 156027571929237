.users-box {
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  min-height: 375px;
  max-height: calc(100vh - 250px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.users-list {
  overflow-x: auto;
}

.headers {
  border-bottom: 1px solid var(--light-grey);
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;

  div {
    width: 16%;

    &:last-child {
      width: 4%;
    }
  }
}

.users {
  padding: 0.5rem;
}

.user {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;

  div {
    width: 16%;
  }

  .menu {
    width: 4%;
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    button {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.create-new-user {
  display: flex;
  justify-content: center;
  padding: 1rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--theme-blue);
    cursor: pointer;
    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }
  }
}

.dropdown {
  button {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    padding-top: 3px;
    padding-bottom: 3px;
  }

  div {
    button {
      background-color: white;
    }

    --bs-dropdown-link-active-bg: var(--theme-green);
  }

  margin-left: auto;
}
