.equal-width-columns {
  flex: 1; // Makes columns flex items with equal width
}

.duplicate {
  color: var(--theme-red);
}

.fit-content-column {
  width: auto !important;
  flex: 0 0 auto !important;
}

.even {
  background-color: rgba(0, 0, 0, 0.075);
}

.checkboxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.indicator {
  margin-left: -5px;
  margin-right: 5px;
  font-size: 18px;
}

.load-item {
  padding: 0.5rem;
  font-size: small;
}

.full-load {
  font-size: small;
  display: flex;
  width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 40.5px;

  .full-menu {
    width: 58px;

    div {
      width: 58px;
    }
  }

  div {
    width: 200px;

    input {
      margin: 0px !important;
    }
  }
}

.show-mobile {
  display: none;
}

.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding-left: 5px;
  padding-right: 5px;
}

.inline-input {
  input {
    margin: 0px !important;
  }
}

.dropdown {
  button {
    background-color: var(--theme-blue);
    border-color: var(--theme-blue);
    padding-top: 3px;
    padding-bottom: 3px;
  }

  div {
    button {
      background-color: white;
    }

    --bs-dropdown-link-active-bg: var(--theme-green);
  }

  margin-left: auto;
  margin-right: 1rem;
}

.inline-icon {
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 0.5rem;
  display: flex;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .hide-tablet {
    display: none;
  }
}

@media (max-width: 800px) {
  .hide-mobile {
    display: none !important; // TODO: Handle this better
  }

  .show-mobile {
    display: flex;
  }
}

.etaTimePicker {
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  user-select: none;
  height: 255px;


  h4 {
    margin-bottom: 20px;
    text-align: center;
    color: var(--theme-blue);
    font-weight: 500;
  }
}

.etaTime {
  font-size: 11px;
  color: var(--theme-blue);
}

.etaButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  width: 300px;
  align-items: center;
}

.cancel {
  user-select: none;
  cursor: pointer;
}